<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='890'>
                    <col width='150'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="content">内容</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="item in data" :key="item.Id">
                        <td>
                            <h2 class="dot" @click="goDetail(item)">{{item.Name}}</h2>
                        </td>
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="goDetail(item)">详情</span>
                                <a-popconfirm title="取消收藏？" ok-text="是" cancel-text="否" @confirm="cancelCollect(item)">
                                    <span>取消收藏</span>
                                </a-popconfirm>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
            :current="params.pageIndex"
        />
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetMyCardList , ToggleCollectCard} from '@/api/collection'
export default {
    props:['keyword'],
    components:{
        Pagination
    },
    data(){
        return{
            data:[],
            params:{
                pageIndex:1,
                pageSize:10,
                key:''
            },
            total:0,
            spinning:false,
        }
    },
    watch:{
        keyword(val){
            console.log(val);
            this.params.key = val
        }
    },
    methods:{
        pageChange(page){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        onSearch(){
            this.params.pageIndex = 1
            this.getList()
        },
        async getList(){
            this.spinning = true
            const { data , total} = await GetMyCardList(this.params)
            this.data = data
            this.total = total
            this.spinning = false
        },
        goDetail(item){
            this.$router.push({ name:'card-info', params: { id:item.Id } })
        },
        cancelCollect(item){
            this.spinning = true
            ToggleCollectCard(item.Id).then(res => {
                this.$message.success('取消成功')
                this.spinning = false
                this.getList()
            }).catch(e => {
                this.spinning = false
            })
        }
    },
    mounted(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>

.content{
    text-align: left !important;
    padding-left: 60px;
}
td{
    height: 60px !important;
    h2{
        font-size: 16px;
        color: rgb(85, 85, 85);
        cursor: pointer;
        &:hover{
            color: #f6a75c;
        }
    }

}
</style>