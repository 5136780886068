<template>
    <div class="browse">
        <a-tabs @change="tabChange" v-model="curTab" :animated="false">
            <template v-for="item in newTabList">
                <a-tab-pane :key="item.value" :tab="item.name">
                    <ModelList
                        v-if="item.value == 0"
                        :keyword="item.key"
                        :ref="item.ref"
                    />
                    <CardList
                        v-if="item.value == 1"
                        :keyword="item.key"
                        :ref="item.ref"
                    />
                    <PostList
                        v-if="item.value == 2"
                        :keyword="item.key"
                        :ref="item.ref"
                    />
                    <LibraryList
                        v-if="item.value == 3"
                        :keyword="item.key"
                        :ref="item.ref"
                    />
                </a-tab-pane>
            </template>
            <div class="search" slot="tabBarExtraContent">
                <a-input-search
                    v-for="item in tabList"
                    :key="item.value"
                    placeholder="请输入检索内容"
                    v-model="item.key"
                    style="width: 300px"
                    v-show="item.value == curTab"
                    @search="search(item.ref)"
                />
            </div>
        </a-tabs>
    </div>
</template>
<script>
import ModelList from '~/components/Personal/Collect/ModelList';
import CardList from '~/components/Personal/Collect/CardList';
import PostList from '~/components/Personal/Collect/PostList';
import LibraryList from '~/components/Personal/Collect/LibraryList';
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
    metaInfo() {
        return {
            title: '收藏记录-个人中心-文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: '收藏记录-个人中心-文物出版社',
                },
                {
                    name: 'description',
                    content: '收藏记录-个人中心-文物出版社',
                },
            ],
        };
    },
    components: {
        ModelList,
        CardList,
        PostList,
        LibraryList,
    },
    computed: {
        ...mapState({
            navList: state => state.app.navList,
        }),
        newTabList() {
            const isShow =
                this.navList?.find(i => i.id === 35975)?.IsShow || false;
            return this?.tabList?.filter(item => {
                if (item.value == 2) {
                    return isShow;
                } else {
                    return true;
                }
            });
        },
    },
    data() {
        return {
            tabList: [
                {
                    value: 0,
                    name: '模型',
                    ref: 'model',
                    key: '',
                },
                {
                    value: 1,
                    name: '卡片',
                    ref: 'card',
                    key: '',
                },
                {
                    value: 2,
                    name: '帖子',
                    ref: 'post',
                    key: '',
                },
                {
                    value: 3,
                    name: '参考资料',
                    ref: 'library',
                    key: '',
                },
                // {
                //     value:2,
                //     name:'帖子'
                // }
            ],
            curTab: 0,
            value: '',
        };
    },
    methods: {
        tabChange(activeKey) {
            this.curTab = activeKey;
        },
        search(name) {
            this.$refs[name][0].onSearch();
        },
    },
};
</script>
<style lang="less" scoped>
.browse {
    padding-top: 20px;
}
</style>
